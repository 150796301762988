/* App */

a, a svg, button svg {
  color: #06D;
  text-decoration: none;
}
a svg, button svg {
  height: 32px;
  width: 32px;
}
a, a svg, button, label, .clickable {
  cursor: pointer;
}

input, select, option, button, textarea {
  font-size: 1em;
  margin: 3px 0;
  vertical-align: middle;
  border: 1px solid #aaa;
}
input, select {
  width: 90%;
  max-width: 400px;
}
.line input, input[type=radio] {
  width: auto;
}
textarea {
  width: 100%;
}
button {
  background-color: #eee;
  padding: 5px 10px;
  font-family: inherit;
}
button.link {
  border: none;
  padding: 0;
  background-color: transparent;
  color: #06D;
}
small {
  color: #666;
}

.header {
  padding: 0 10px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}


/* Login */

form[name=login] {
  text-align: center;
  margin-top: 25vh;
  padding: 20px;
}

form[name=login] input, form[name=login] button {
  display: block;
  width: calc(100% - 22px);
  max-width: 400px;
  margin: 20px auto;
  padding: 10px;
}
form[name=login] button {
  width: 150px;
}

.error {
  color: #E33;
}

/* Discount codes */

.discounts {
  border-top: 2px solid black;
  border-bottom: 1px solid black;
  padding: 10px;
}
.discounts form {
  display: flex;
  align-items: center;
  max-width: 500px;
}
.discounts input {
  flex: 1;
  margin-right: 2px;
  text-transform: uppercase;
}
.discounts button {
  padding: 1px 9px;
  margin-left: 12px;
}


/* List */

h2 {
  margin-top: 0;
}

.day {
  border-top: 1px solid black;
  padding: 10px;
}

.inline {
  background-color: #FF9;
  padding: 10px 40px 10px 10px;
  position: relative;
}
.confirmed {
  background-color: #BFB;
}

label {
  white-space: nowrap;
  display: block;
  margin: 5px 0 10px;
}
label.line {
  padding-right: 10px;
  display: inline-block;
}
.book button {
  margin: 10px 0;
}


/* Avail */

.closed {
  color: #E33;
}
.partner {
  color: #F82;
}
.open {
  color: #2B2;
}
.close {
  position: absolute;
  top: 0;
  right: 0;
  border: none;
  background-color: transparent;
}


/* Book */

ul {
  padding: 0;
  list-style: none;
}
li {
  margin: 5px 0;
}
